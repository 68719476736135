<template>
  <el-card title="添加资产">
    <el-form ref="form"
             v-loading="loading"
             :model="form"
             class="assest-save-form"
             label-width="120px">
      <b-row :gutter="20"
             class="mr10">
        <b-col md="6">
          <el-form-item label="名称"
                        prop="name"
                        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]">
            <el-input  v-model="form.name"
                      maxlength="20" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="数据来源"
                        prop="dataSourceType"
                        :rules="[ { required: true, message: '数据来源不能为空', trigger: 'change' }]">
            <el-select v-model="form.dataSourceType"
                       placeholder="请选择数据来源">
              <el-option v-for="(item,index) in dataSourceTypeOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="对接地址"
                        prop="url"
                        :rules="[ { required: true, message: '对接地址不能为空', trigger: 'change' }]">
            <el-input  v-model="form.url"
                      placeholder="请输入对接地址" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="入库方式"
                        prop="storageMethod"
                        :rules="[ { required: true, message: '入库方式不能为空', trigger: 'change' }]">
            <el-select v-model="form.storageMethod"
                       placeholder="请选择入库方式">
              <el-option v-for="(item,index) in putStorageOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="账号"
                        prop="account"
                        :rules="[ { required: true, message: '账号不能为空', trigger: 'change' }]">
            <el-input  v-model="form.account"
                      placeholder="请输入账号"
                      maxlength="20" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="密码"
                        prop="password"
                        :rules="[ { required: true, message: '密码不能为空', trigger: 'change' }]">
            <el-input v-model="form.password"
                      placeholder="请输入密码"
                      type="password" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="同步时间"
                        prop="syncTime"
                        :rules="[ { required: true, message: '负责人不能为空', trigger: 'change' }]">
            <el-time-picker v-model="form.syncTime"
                            is-range
                            format="HH:mm"
                            value-format="HH:mm"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="同步频率"
                        class="select-inline">
            <el-input v-model="form.syncFrequency"
                      class="mr10"
                      style="width: 50%"
                      placeholder="请输入同步频率"
                      type="number"/>
            <div v-show="syncFrequencyErrorShow" class="el-form-item__error">{{syncFrequencyError}}</div>
            <el-select @change="clearSyncFrequency" v-model="form.syncFrequencyType">
              <el-option v-for="(item,index) in [{label: '分钟',value: 2},{label: '小时',value: 1}]"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="状态">
            <el-select v-model="form.status"
                       placeholder="请选择状态">
              <el-option v-for="(item,index) in statusOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  success, error, verifyPhone, treeDataTranslate,
} from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import { queryUserOptions } from '@/api/system/system'
import { getAllProduct } from '@/api/enterprise/product'
import { saveOrUpdateDataFound, GetDataFindById } from '@/api/dataFound/dataFound'

export default {
  data () {
    return {
      syncFrequencyErrorShow: false,
      syncFrequencyError: '',
      dataSourceTypeOptions: [
        { label: '昂楷数据扫描器', value: 1 },
      ],
      statusOptions: [
        { label: '启用', value: 1 },
        { label: '停用', value: 2 },
      ],
      putStorageOptions: [
        { label: '自动', value: 1 },
        { label: '手动', value: 2 },
      ],
      countryProps: {
        emitPath: false,
      },
      users: [],
      loading: false,
      form: {
        id: '',
        name: '',
        dataSourceType: '',
        password: '',
        syncFrequencyType: 2,
        status: 1,
        syncMethod: 1,
        storageMethod: '',
        syncTime: '',
        account: '',
        syncFrequency: '',
        url: '',
      },
    }
  },
  created () {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.getDataFindById()
    }
  },
  mounted () {
    this.navActiveInit(1, '个人信息资产', '数据智能')
  },
  destroyed () {
    this.navActiveInit(2, '个人信息资产', '数据智能', '/infoAssest')
  },
  methods: {
    clearSyncFrequency() {
      this.syncFrequencyErrorShow = false
      this.syncFrequencyError = ''
      this.form.syncFrequency = ''
    },
    getDataFindById () {
      GetDataFindById({ id: Number(this.form.id) }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const d = resData.data
          this.form.name = d.name
          this.form.id = d.id
          this.form.account = d.account
          this.form.dataSourceType = d.dataSourceType
          this.form.password = d.password
          this.form.status = d.status
          this.form.url = d.url
          this.form.storageMethod = d.storageMethod
          this.form.syncFrequency = d.syncFrequency
          this.form.syncFrequencyType = d.syncFrequencyType
          this.form.syncTime = [d.syncBeginTime, d.syncEndTime]

        }
      })
    },
    back () {
      sessionStorage.setItem('activeName', '2')
      this.$router.push('/infoAssest')
    },
    submitForm () {
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          const submitData = {
            name: this.form.name,
            account: this.form.account,
            dataSourceType: this.form.dataSourceType,
            password: this.form.password,
            status: this.form.status,
            syncMethod: 1,
            storageMethod: this.form.storageMethod,
            syncBeginTime: this.form.syncTime[0],
            syncEndTime: this.form.syncTime[1],
            url: this.form.url,
            syncFrequencyType: this.form.syncFrequencyType,
            syncFrequency: Number(this.form.syncFrequency)
          }
          if (this.form.syncFrequencyType === 1) {
            // submitData.syncFrequency = this.form.syncFrequency * 60
          }
          if (this.form.id != '') {
            submitData.id = this.form.id
          }
          saveOrUpdateDataFound(submitData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
