import { get, post } from '@/libs/axios'


export const GetDataFindById = params => post('/dataMap/dataFind/GetDataFindById', params)
export const GetDataFindListByPage = params => post('/dataMap/dataFind/GetDataFindListByPage', params)
export const DeleteDataFindResultByIds = params => post('/dataMap/dataFind/DeleteDataFindResultByIds', params)


export const UpdateDataFindStatus = params => post('/dataMap/dataFind/UpdateDataFindStatus', params)
export const GetDataFindResultInfoList = params => post('/dataMap/dataFind/GetDataFindResultInfoList', params)


export const DeleteDataFindByIds = params => post('/dataMap/dataFind/DeleteDataFindByIds', params)
export const saveOrUpdateDataFound = params => {
  const submitUrl = params.id ? '/dataMap/dataFind/UpdateDataFind' : '/dataMap/dataFind/CreateDataFind'
  return post(submitUrl, params)
}
